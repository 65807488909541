/** @jsx jsx */
import { jsx } from "theme-ui";
import { Section } from "maker-ui";
import HtmlDiv from "sites-common/components/HtmlDiv";
import { useEffect, useState } from "react";
import useDonationConf from "gatsby-plugin-hfn-profile/components/DonationComponent/hooks/useDonationConf";
import { compareStartEndDateWithCurrentDate } from "sites-common/utils/validations";
import theme from "../mark-ui/theme";
import landingPageUrl from "../../data/wp-pages.json";

const isBrowser = () => typeof window !== "undefined";

const SiteAccessRestriction = () => {
  const [restrictMsg, setRestrictMsg] = useState();
  const [maintenanceMsg, setMaintainanceMsg] = useState("");
  const { conf } = useDonationConf();

  useEffect(() => {
    if (!isBrowser) {
      return;
    }
    const getOrg = landingPageUrl.wpPages.find((i) => {
      if (window.location.pathname.includes("offline")) {
        return null;
      }
      return (
        window.location.pathname.replace(/\//g, "") ===
        i.path.replace(/\//g, "")
      );
    });
    if (getOrg?.YEC) {
      setRestrictMsg(getOrg.org);
    }
    if (conf?.site_maintenance?.show) {
      setMaintainanceMsg(conf?.site_maintenance?.msg);
    }
  }, [conf]);

  return (
    <Section>
      {maintenanceMsg && (
        <div sx={theme.overlay.SiteAccessRestrictionBackground}>
          <div sx={theme.overlay.SiteAccessRestrictionBackgroundPopUp}>
            <HtmlDiv htmlString={maintenanceMsg} />
          </div>
        </div>
      )}
      {restrictMsg &&
        !maintenanceMsg &&
        conf.YEC &&
        conf.YEC?.map((data, key) => {
          const x = `y${key}x`;
          const checkIsDate = compareStartEndDateWithCurrentDate(data);

          if (checkIsDate) {
            return (
              <div sx={theme.overlay.SiteAccessRestrictionBackground}>
                <div sx={theme.overlay.SiteAccessRestrictionBackgroundPopUp}>
                  <HtmlDiv key={x} htmlString={data.msg[restrictMsg]} />
                </div>
              </div>
            );
          }
          return null;
        })}
    </Section>
  );
};

export default SiteAccessRestriction;
